import React, { FC, useState } from "react";
import { LatestArticlesCardListProps } from "../generated-types";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { Splide, SplideProps, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { useSitecoreContext } from "~/foundation/Jss";
import { themable } from "~/foundation/Theme";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { Splide as SplideItem } from "@splidejs/splide";
import { GeneratedCard, GeneratedCardModel } from "~/foundation/Components/GeneratedCard";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

const LatestArticlesCardList: FC<LatestArticlesCardListProps> = ({ fields, rendering }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [isSlider, setIsSlider] = useState(false);
	const [isMdBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.md})`);
	const showPlaceholders = fields?.togglePlaceholderImage?.value ? "1" : "";

	const splideProps: SplideProps = {
		options: {
			lazyLoad: "nearby",
			pagination: false,
			snap: true,
			drag: isSlider,
			gap: "1.25rem",
			autoplay: false,
			type: "slide",
			fixedWidth: "calc( 25% - 15px )",
			fixedHeight: "auto",
			cover: false,
			arrows: false,
			flickPower: 120,
			speed: 800,
			easing: "cubic-bezier(0.25, 1, 0.5, 1)",
			waitForTransition: isSlider,
			focus: 0,
			direction: isRtl ? "rtl" : "ltr",
			mediaQuery: 'min',
			perPage: 1,
			breakpoints: {
				700: {
					perPage: 2,
				}
			}
		}
	};

	const updateProgress = (splide: SplideItem, index: number) => {
		setIsSlider(splide.Components.Layout.isOverflow());
		const totalItems = splide.Components.Controller.getEnd();
		const progressBar = splide.root.querySelector("[data-progress-bar] span") as HTMLSpanElement;
		const rate = Math.min((index + 1) / (totalItems + 1), 1);
		progressBar.style.width = `${(100 * rate)}%`;
	}

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]} overflow="hidden">
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper
				py="0"
				px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}
				sx={{
					".splide__track": {
						overflow: "visible"
					}
				}}>
				<Splide
					tag="div"
					onResized={(splide) => updateProgress(splide, splide.Components.Controller.getIndex())}
					onMove={updateProgress}
					onMounted={(splide) => updateProgress(splide, 0)}
					{...splideProps}
					hasTrack={false}
				>
					<SplideTrack>
						{(rendering.customData?.items || []).map((card, index) => {
							const cardModel: GeneratedCardModel = {
								...card,
								imageSrc: card.image?.value?.src,
								imageAlt: card.image?.value?.alt
							}

							return (
								<SplideSlide
									key={card.id}
									style={{
										minWidth: isMdBreakpointOrAbove ? "var(--chakra-sizes-cardWidth)" : "var(--chakra-sizes-cardWidthMobile)",
										maxWidth: "430px"
									}}
									data-tracking-id="generated_cards_listing"
									data-tracking-data={JSON.stringify({ text: cardModel.title, index: index + 1, count: rendering.customData!.items!.length })}
								>
									<GeneratedCard
										card={cardModel}
										index={index}
										isRtl={isRtl}
										pageEditing={sitecoreContext.pageEditing || false}
										useWithSplide={true}
										showPlaceholders={showPlaceholders}
										headerTag={(fields?.headline?.value || fields?.subHeadline?.value) ? "h3" : "h2"} />
								</SplideSlide>
							);
						})}
					</SplideTrack>
					<Box
						display={isSlider ? "block" : "none"}
						data-progress-bar
						className="splide__progressbar"
						mt={["10", null, null, "2.65rem"]}
						w="100%"
						h="3px"
						borderRadius="2px"
						position="relative"
						bgColor="progressBar.default">
						<Box
							as="span"
							borderRadius="2px"
							h="100%"
							transitionDuration=".5s"
							transitionProperty="width"
							transitionTimingFunction="aramcoCubic"
							position="absolute"
							overflow="hidden">
							<Box
								w="100%"
								h="100%"
								borderRadius="2px"
								position="absolute"
								bg="gradients.brand"
							/>
						</Box>
					</Box>
				</Splide>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable({ defaultColorThemeId: "2" })(LatestArticlesCardList);